@import '~@fortawesome/fontawesome-free/scss/fontawesome';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes mapLoaderAnimation {
  0% { opacity: 1; transform: scale(1) }
  50% { opacity: .25; transform: scale(.95) }
  100% { opacity: 1; transform: scale(1) }
}

.HomePage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  // height: 100vh;

  display: flex;

  .map-container {
      flex-grow: 1;
      position: relative;
      // top: 0;
      // left: 0;
      width: 100%;
      // height: 100%;
      // z-index: 0;
  }

  .side-panel {
      position: relative;
      max-width: 450px;
      flex-basis: 450px;
      height: 100vh;
      flex-shrink: 0;

      -webkit-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.5);
      -moz-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.5);
      box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.5);
      z-index: 10;
  }
}